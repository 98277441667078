<template>
	<el-scrollbar class="seller-scrollbar">
		<el-card class="seller-card">
            <el-breadcrumb slot="header" separator="/">
                <el-breadcrumb-item :to="{ name: 'contentManage' }">{{ $t('seller.breadcrumb.regularOperate') }}</el-breadcrumb-item>
                <el-breadcrumb-item>{{ $t('seller.breadcrumb.dealConversion') }}</el-breadcrumb-item>
            </el-breadcrumb>
			<div class="page-tips">{{ $t('seller.customer.regularInfo1') }}</div>
			<div class="page-steps">
				<div class="step-item active">
					<i class="item-status el-icon-success"></i>
					<img class="item-icon" src="~@/assets/images/seller/icon-snapshot-1.png" alt="">
					<span class="item-text">{{ $t('seller.customer.regularStep1') }}</span>
				</div>
				<div class="step-item active">
					<i class="item-status el-icon-success"></i>
					<img class="item-icon" src="~@/assets/images/seller/icon-snapshot-1.png" alt="">
					<span class="item-text">{{ $t('seller.customer.regularStep2') }}</span>
				</div>
				<div class="step-item">
					<i class="item-status"></i>
					<img class="item-icon" src="~@/assets/images/seller/icon-snapshot-2.png" alt="">
					<span class="item-text">{{ $t('seller.customer.regularStep3') }}</span>
				</div>
				<div class="step-item active">
					<i class="item-status el-icon-success"></i>
					<img class="item-icon" src="~@/assets/images/seller/icon-snapshot-1.png" alt="">
					<span class="item-text">{{ $t('seller.customer.regularStep4') }}</span>
				</div>
			</div>
			<div class="page-wrap">
				<el-form label-width="15%">
					<div class="page-section">
						<div class="section-title">{{ $t('seller.customer.regularStep1') }}</div>
						<div class="section-wrap">
							<el-form-item :label="$t('seller.screen.title')">
								<el-input v-model="postForm.title" maxlength="60"></el-input>
							</el-form-item>
							<el-form-item :label="$t('seller.customer.operatSceneText11')">
								<div class="crowd-list" v-if="regularCrowd">
									<div class="item">
										<div class="item-tag">{{ $t('seller.customer.operatSceneText12') }}</div>
										<div class="item-main">
											<i class="item-icon"><img src="~@/assets/images/seller/icon-snapshot-3.png" alt=""></i>
											<span class="name">{{ regularCrowd.title }}</span>
										</div>
									</div>
									<div class="item">
										<div class="item-tag">{{ $t('seller.customer.operatSceneText13') }}</div>
										<div class="item-main">
											<div class="count">{{ regularCrowd.predicts }}</div>
										</div>
									</div>
								</div>
							</el-form-item>
						</div>
					</div>
					<div class="page-section">
						<div class="section-title">{{ $t('seller.customer.regularStep2') }}</div>
						<div class="section-wrap">
							<el-form-item :label="$t('seller.dialogTitle.goods')">
								<div><el-tag type="purple" size="small" effect="dark">{{ $t('seller.customer.regularInfo2') }}</el-tag></div>
								<div class="section-notice">
									<img class="notice-icon" src="~@/assets/images/seller/icon-snapshot-2.png" alt="" />
									<span>{{ $t('seller.customer.regularInfo3-1') }} {{ goodsList.length || 8 }} {{ $t('seller.customer.regularInfo3-2') }}</span>
								</div>
								<el-scrollbar class="section-goods">
									<div class="goods-list">
										<div class="item" v-for="item in goodsList" :key="item.goods_id">
											<div class="item-pic"><img :src="item.default_image" alt=""></div>
											<div class="item-main">
												<div class="name">{{ item.goods_name }}</div>
												<div class="text">
													<span class="price">￥{{ item.price * 1}}</span>
													<span class="sale">{{ item.sales_num }}{{ $t('seller.customer.regularInfo4') }}</span>
												</div>
											</div>
										</div>
									</div>
								</el-scrollbar>
							</el-form-item>
						</div>
					</div>
					<div class="page-section">
						<div class="section-title">{{ $t('seller.customer.regularStep3') }}</div>
						<div class="section-wrap">
							<el-form-item :label="$t('seller.dialogTitle.coupon')">
								<div><el-tag type="purple" size="small" effect="dark">{{ $t('seller.customer.regularInfo5') }}</el-tag></div>
								<div class="coupon-tools">
									<router-link :to="{ name: 'couponCreate',query: { channel: 1 }}">
										<el-button type="primary" size="medium" round>{{ $t('seller.customer.regularInfo6') }}</el-button>
									</router-link>
								</div>
								<div class="coupon-wrap" v-if="couponList.length > 0">
									<el-radio-group v-model="checkCoupon" class="coupon-list" @change="hancleChangeCoupon">
										<el-radio-button v-for="item in couponList" :key="item.coupon_id" :label="item">
											<div class="item-head">{{ item.name }}</div>
											<div class="item-main">
												<div class="quota"><span class="price">￥<span>{{ item.money }}</span></span>{{ $t('seller.customer.operatSceneText14') }}</div>
												<div class="text">{{ $t('seller.unit.full') }}{{ item.quota }}{{ $t('seller.singletreasure.reduce') }}{{ item.money }}</div>
												<div class="text">{{ $t('seller.tableHead.validity') }}{{ item.use_begin_time | parseTime('{y}-{m}-{d} {h}:{i}') }}~{{ item.use_end_time | parseTime('{y}-{m}-{d} {h}:{i}') }}</div>
											</div>
											<div class="item-bottom">
												<span>{{ $t('seller.tableHead.limitedCopies') + '：' }}{{ item.limit_give_num }}</span>
												<span>{{ $t('seller.customer.regularInfo7') + '：' }}{{ item.issuable_num }}</span>
											</div>
										</el-radio-button>
									</el-radio-group>
									<div class="coupon-bottom">
										<el-button type="danger" size="small">{{ $t('seller.customer.regularInfo8') + '：100' }}</el-button>
										<span class="count">{{ $t('seller.customer.regularInfo7') + '：' }}{{ checkCoupon?checkCoupon.issuable_num:0}}</span>
										<span class="diff" v-if="checkCoupon">{{ $t('seller.customer.regularInfo9') + '：' }}{{ checkCoupon.issuable_num > 100 ? 0:100-checkCoupon.issuable_num}}</span>
										<span class="diff" v-else>{{ $t('seller.customer.regularInfo9') + '：100' }}</span>
									</div>
								</div>
								<div v-else class="coupon-emtpy"><span>{{ $t('seller.customer.regularInfo10') }}</span></div>
							</el-form-item>
						</div>
					</div>
					<div class="page-section">
						<div class="section-title">{{ $t('seller.customer.regularStep4') }}</div>
						<div class="section-wrap">
							<el-form-item :label="$t('seller.coupon.paneTitle1')">
								<div><el-tag type="purple" size="small" effect="dark">{{ $t('seller.customer.regularInfo11') }}</el-tag></div>
								<div class="section-notice">
									<img class="notice-icon" src="~@/assets/images/seller/icon-snapshot-2.png" alt="" />
									<span>{{ $t('seller.customer.regularInfo12') }}</span>
								</div>
								<div class="section-channel">
									<div class="channel-item">
										<div class="item-check">
											<el-checkbox v-model="postForm.is_poster" :true-label="1" :false-label="0"></el-checkbox>
										</div>
										<div class="item-main">
											<svg-icon icon-class="poster" class="item-icon"></svg-icon>
											<div class="item-box">
												<div class="item-tips">
													<div class="name">{{ $t('seller.customer.operatSceneText20') }}</div>
													<div class="text">{{ $t('seller.customer.regularInfo13') }}</div>
												</div>
												<el-form ref="posterForm" :model="posterForm" :rules="posterRules" label-width="90px" size="small">
													<el-form-item :label="$t('seller.tableHead.posterEditor')" prop="banner">
														<div class="item-upload" v-if="posterForm.banner">
															<img :src="posterForm.banner" alt="">
															<div class="btn-delete" @click="handleDeleteBanner">{{ $t('seller.actions.delete') }}</div>
														</div>
														<div v-else class="item-upload" @click="handleShowUpload">
															<i class="el-icon-plus"></i>
															<p>(上传大小640*214像素)</p>
														</div>
														<el-dialog
															:title="$t('seller.dialogTitle.image')"
															width="960px"
															:close-on-click-modal="false"
															:close-on-press-escape="false"
															:visible.sync="dialogVisibleUpload"
															:before-close="handleCloseUpload"
															custom-class="seller-dialog">
															<upload-material
																ref="uploadImage"
																:key="cropperRandom"
																@get-uplod-action="handleUploadAction"
																:upload-type="true"
																:upload-action="'cropping'"
																:cropper-scale="cropperScale"
																:min-width="640">
															</upload-material>
														</el-dialog>
													</el-form-item>
													<el-form-item :label="$t('seller.formItem.jumpLink')" prop="link_type">
														<el-radio-group v-model="posterForm.link_type">
															<el-radio :label="1" :disabled="!couponList.length > 0">{{ $t('seller.customer.operatSceneText22') }}</el-radio>
															<el-radio :label="2">{{ $t('seller.customer.operatSceneText23') }}<span style="margin-left: 10px;"><el-input v-model="posterForm.link_url" style="width: 200px;"></el-input></span></el-radio>
														</el-radio-group>
													</el-form-item>
													<el-form-item :label="$t('seller.campaign.activityTime')" prop="time_from">
														<el-date-picker
															v-model="posterTimes"
															type="datetimerange"
															:range-separator="$t('seller.unit.to')"
															:start-placeholder="$t('seller.placeholder.startDate')"
															:end-placeholder="$t('seller.placeholder.endDate')"
															value-format="timestamp"
															:picker-options="pickerOptions"
															@change="handleChangePickerPoster"
															style="width: 380px">
														</el-date-picker>
													</el-form-item>
												</el-form>
											</div>
										</div>
									</div>
									<div class="channel-item">
										<div class="item-check">
											<el-checkbox v-model="postForm.is_recommend" :true-label="1" :false-label="0"></el-checkbox>
										</div>
										<div class="item-main">
											<svg-icon icon-class="shop-push" class="item-icon"></svg-icon>
											<div class="item-box">
												<div class="item-tips">
													<div class="name">{{ $t('seller.customer.regularInfo14') }}</div>
													<div class="text">{{ $t('seller.customer.regularInfo15') }}</div>
												</div>
												<el-form ref="pushForm" :model="pushForm" :rules="pushRules" label-width="90px" size="small">
													<el-form-item :label="$t('seller.campaign.activityTime')" prop="time_from">
														<el-date-picker
															v-model="pushTimes"
															type="datetimerange"
															:range-separator="$t('seller.unit.to')"
															:start-placeholder="$t('seller.placeholder.startDate')"
															:end-placeholder="$t('seller.placeholder.endDate')"
															value-format="timestamp"
															:picker-options="pickerOptions"
															@change="handleChangePickerPush"
															style="width: 380px">
														</el-date-picker>
													</el-form-item>
												</el-form>
											</div>
										</div>
									</div>
								</div>
							</el-form-item>
						</div>
					</div>
				</el-form>
			</div>
			<div class="page-bottom" v-if="!this.$route.params.id>0">
				<div class="text">
					<!-- 智能配置已完成<span>75% </span>未完成所有智能配置将影响预估效果 -->
				</div>
				<div>
					<el-button round size="medium">{{ $t('seller.actions.backCrowdList') }}</el-button>
					<el-button type="primary" round size="medium" @click="handleSave" v-loading="saveLoading">{{ $t('seller.actions.onekeyPromote') }}</el-button>
				</div>
			</div>
		</el-card>
	</el-scrollbar>
</template>

<script>
import { fetchRegularInfo, fetchRegularCrowd, fetchRegularGoods, fetchRegularCoupon, createRegular  } from "@/api/seller/customer"
import uploadMaterial from '@/components/seller/uploadMaterial'
export default {
	components: {
		uploadMaterial
	},
	data() {
		var that = this
		return {
			regularCrowd: {},
			goodsList: [],
			couponList: [],
			postForm: {
				title: '',
				goods_ids: '',
				coupon_id: '',
				is_poster: 0,
				is_recommend: 1,
				type: 0
			},
			posterForm: {
				banner: '',
				link_type: 2,
				link_url: '',
				time_from: '',
				time_to: ''
			},
			posterRules: {
				banner: [{ required: true, message: that.$t('seller.validator.posterPic') }],
				link_type: [{ required: true, message: that.$t('seller.validator.jumpLink') }],
				time_from: [{ required: true, message: that.$t('seller.validator.activityTime') }]
			},
			posterTimes: '',
			pushForm: {
				time_from: '',
				time_to: ''
			},
			pushRules: {
				time_from: [{ required: true, message: that.$t('seller.validator.activityTime') }]
			},
			pushTimes: '',
			pickerOptions: {
				disabledDate(time) {
					return time.getTime()<Date.now()-86400000;
				},
			},
			checkCoupon: '',
			dialogVisibleUpload: false,
			cropperRandom: Math.random(),
			cropperScale: [320,107],
			saveLoading: false
		}
	},
	created () {
		this.getData()
	},
	methods: {
		async getData() {
			try {
				if(this.$route.params.id) {
					const { data } = await fetchRegularInfo({operate_id: this.$route.params.id})
					// this.crowdInfo = data
					this.postForm = data
					if(data.is_poster === 1) {
						this.posterForm = data.poster_content
						this.posterTimes = [this.posterForm.time_from*1000,this.posterForm.time_to*1000]
					}
					if(data.is_recommend === 1) {
						this.pushForm = data.recommend_content
						this.pushTimes = [this.pushForm.time_from*1000,this.pushForm.time_to*1000]
					}
					if(data.crowd) {
						this.regularCrowd = data.crowd
					}
					if(data.coupon) {
						this.checkCoupon = data.coupon
						this.couponList.push(data.coupon)
					}
				} else {
					const regular_crowd = await fetchRegularCrowd()
					this.regularCrowd = regular_crowd.data
					const old_coupon = await fetchRegularCoupon()
					this.couponList = old_coupon.data.list
				}
				const old_goods = await fetchRegularGoods()
				this.goodsList = old_goods.data.list
			} catch (error) {
				console.log(error)
			}
		},
		handleChangePickerPoster(times) {
			if(times) {
				this.posterForm.time_from = parseInt(times[0] / 1000)
				this.posterForm.time_to = parseInt(times[1] / 1000)
			} else {
				this.posterForm.time_from = ''
				this.posterForm.time_to = ''
			}
		},
		handleChangePickerPush(times) {
			if(times) {
				this.pushForm.time_from = parseInt(times[0] / 1000)
				this.pushForm.time_to = parseInt(times[1] / 1000)
			} else {
				this.pushForm.time_from = ''
				this.pushForm.time_to = ''
			}
		},
		hancleChangeCoupon(val) {
			if(val) {
				this.postForm.coupon_id = val.coupon_id
			}
		},
		handleDeleteBanner() {
			this.posterForm.banner = ''
		},
		handleShowUpload() {
			this.cropperRandom = Math.random()
			this.dialogVisibleUpload = true
		},
		handleCloseUpload() {
			this.dialogVisibleUpload = false
		},
		handleUploadAction(data) {
			if(data.type === 1) {
				this.posterForm.banner = data.img_url
			}
			this.dialogVisibleUpload = false
		},
		handleSave() {
			if(this.postForm.title === '') {
				this.$message.error(this.$t('seller.errorMsg.activityTitle'))
				return false
			}
			var falg = false
			if(this.postForm.is_poster === 1) {
				this.$refs.posterForm.validate(valid => {
					if(!valid) {
						falg = true
						return false
					}
				})
			}
			if(this.postForm.is_recommend === 1) {
				this.$refs.pushForm.validate(valid => {
					if(!valid) {
						falg = true
						return false
					}
				})
			}
			if(falg) {
				return false
			}
			const params = {
				...this.postForm,
				crowd_id: this.regularCrowd.crowd_id,
				poster_content: JSON.stringify(this.posterForm),
				recommend_content: JSON.stringify(this.pushForm)
			}
			createRegular(params).then(() => {
				this.$confirm(this.$t('seller.confirm.promoteText'), this.$t('seller.confirm.promoteTitle'), {
					confirmButtonText: this.$t('seller.actions.confirm'),
					cancelButtonText: this.$t('seller.actions.cancel'),
					type: 'success'
				}).then(() => {
					this.$router.push({name:'customerRegular'})
				}).catch(() => { })
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.seller-card {
	.page-tips {
		font-size: 20px;
		margin-bottom: 20px;
	}
	.page-steps {
		padding: 24px;
		display: flex;
		background: linear-gradient(180deg,#3f3593 57%,#355194);
		.step-item {
			flex: 1;
			height: 40px;
			border-radius: 3px;
			margin-right: 19px;
			display: flex;
			align-items: center;
			color: $--seller-primary;
			background: #fff;
			justify-content: center;
			position: relative;
			.item-status {
				top: 10px;
				left: 14px;
				font-size: 19px;
				width: 19px;
				height: 19px;
				border-radius: 50%;
				display: inline-block;
				position: absolute;
				box-shadow: 0 0 0 1px $--seller-primary inset;
			}
			.item-icon {
				width: 21px;
				height: 16px;
			}
			.item-text {
				margin-left: 5px;
			}
			&.active {
				color: #fff;
				background-color: #7160ff;
				.item-status {
					box-shadow: none;
					border-radius: 0;
				}
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
	.page-wrap {
		padding: 17px 25px 0;
		.page-section {
			border-radius: 6px;
			margin-bottom: 20px;
			overflow: hidden;
			.section-title {
				color: #000;
				height: 42px;
				line-height: 42px;
				padding: 0 24px;
				background-color: #fff;
				font-size: 14px;
			}
			.section-wrap {
				overflow: hidden;
				min-height: 100px;
				padding: 17px 24px 0;
				background: linear-gradient(180deg,rgba(113,96,255,.1),#fff);
			}
			.section-notice {
				margin-top: 18px;
				height: 37px;
				border-radius: 3px;
				padding: 0 12px;
				display: flex;
				align-items: center;
				background: #e9e7ff;
				font-size: 14px;
				color: #333;
				.notice-icon {
					width: 21px;
					height: 17px;
					margin-right: 6px;
				}
			}
			.section-goods {
				margin-top: 18px;
				.goods-list {
					white-space: nowrap;
					.item {
						display: inline-block;
						width: 120px;
						margin-right: 12px;
						margin-bottom: 12px;
						.item-pic {
							width: 120px;
							height: 120px;
							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}
						.item-main {
							padding: 10px 0;
							.name {
								width: 104px;
								height: 34px;
								line-height: 17px;
								font-size: 12px;
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
								color: #666666;
							}
							.text {
								margin-top: 3px;
								line-height: 17px;
								font-size: 12px;
							}
							.price {
								color: #ff5000;
								margin-right: 4px;
							}
							.sale {
								color: #999;
							}
						}
					}
				}
			}
			.coupon-tools {
				display: flex;
				justify-content: flex-end;
				margin-bottom: 12px;
			}
			.coupon-wrap {
				.coupon-bottom {
					padding: 15px 24px;
					display: flex;
					align-items: center;
					line-height: normal;
					background-color: #fff1f1;
					span {
						font-size: 12px;
						margin-left: 32px;
					}
					.count {
						color: #111;
					}
					.diff {
						color: #f23c3c;
					}
				}
			}
			.coupon-emtpy {
				height: 188px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #999;
				font-size: 14px;
				border: 1px solid #e2e2e2;
				border-radius: 3px;
				background-color: #fdfdfd;
			}
			.section-channel {
				.channel-item {
					display: flex;
					margin-bottom: 18px;
					.item-check {
						width: 43px;
						flex-shrink: 0;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: #eeedff;
					}
					.item-main {
						flex: 1;
						width: 0;
						display: flex;
						padding: 20px 24px 0;
						background-color: #faf9ff;
					}
					.item-icon {
						font-size: 36px;
						flex-shrink: 0;
					}
					.item-box {
						flex: 1;
						width: 0;
						margin-left: 12px;
						::v-deep .el-radio-group {
							white-space: normal;
							.el-radio {
								margin-bottom: 10px;
							}
						}
					}
					.item-tips {
						font-size: 14px;
						line-height: 1;
						margin-bottom: 18px;
						.name {
							color: #333;
						}
						.text {
							color: #666;
							margin-top: 8px;
						}
					}
					.item-upload {
						width: 320px;
						height: 107px;
						display: flex;
						cursor: pointer;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						border: 1px dashed #cbcbcb;
						position: relative;
						[class^=el-icon-] {
							font-size: 32px;
							color: $--seller-primary;
						}
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
						p {
							color: #666;
						}
						.btn-delete {
							bottom: 0;
							left: 0;
							width: 100%;
							color: #fff;
							cursor: pointer;
							height: 24px;
							line-height: 24px;
							font-size: 12px;
							position: absolute;
							text-align: center;
							background: rgba(57,120,247,.9);
						}
					}
				}
			}
		}
		::v-deep .el-form {
			.el-form-item__content {
				margin-right: 15%;
			}
		}
		.crowd-list {
			display: flex;
			.item {
				flex: 1;
				height: 98px;
				border-radius: 3px;
				margin-right: 20px;
				position: relative;
				background-color: #e0ddff;
				.item-tag {
					top: 0;
					left: 0;
					width: 65px;
					height: 22px;
					line-height: 22px;
					text-align: center;
					border-top-left-radius: 3px;
					border-bottom-right-radius: 8px;
					background-color: #796aff;
					color: #fff;
					position: absolute;
				}
				.item-main {
					margin: 34px 0 0 45px;
					display: flex;
					align-items: center;
					.name {
						margin-left: 10px;
						color: #111;
						font-weight: 700;
					}
					.count {
						color: #796aff;
						font-size: 32px;
						line-height: 1;
						font-weight: 700;
					}
					.item-icon {
						width: 48px;
						height: 48px;
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 50%;
						background-color: #796aff;
						img {
							width: 21px;
							height: 26px;
						}
					}
				}
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
	.page-bottom {
		display: flex;
		align-items: center;
		padding: 0 24px;
		justify-content: space-between;
		.text {
			color: #796aff;
			font-size: 16px;
			span {
				font-size: 20px;
				margin: 0 4px;
			}
		}
	}
}

::v-deep .el-tag {
	&.el-tag--purple {
		border-radius: 12px;
		border-color: #7160ff;
		background-color: #7160ff;
	}
}
::v-deep .el-radio-group {
	&.coupon-list {
		display: block;
		.el-radio-button {
			width: 344px;
			height: 186px;
			margin-right: 12px;
			margin-bottom: 12px;
			.el-radio-button__inner {
				text-align: left;
				padding: 0 12px;
				display: block;
				border-radius: 4px;
				border: 1px solid #e2e2e2;
				background-color: #ffffff;
				.item-head {
					height: 42px;
					line-height: 42px;
					font-size: 14px;
					color: #333;
				}
				.item-main {
					padding: 8px 24px 14px;
					border-radius: 6px;
					color: #fd1717;
					background-color: #ffe8e8;
					.quota {
						font-size: 12px;
					}
					.price {
						font-size: 18px;
						margin-right: 6px;
						span {
							font-size: 32px;
							font-weight: 500;
						}
					}
					.text {
						font-size: 12px;
						margin-top: 12px;
					}
				}
				.item-bottom {
					height: 42px;
					display: flex;
					font-size: 12px;
					color: #666;
					justify-content: space-between;
					align-items: center;
				}
			}
			&.is-active {
				.el-radio-button__inner {
					box-shadow: none;
					border-color: #fd1717;
				}
			}
		}
	}
}
</style>